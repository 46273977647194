/* Modal overlay styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden; /* Prevent body scrolling */
}

/* Modal container styling */
.modal-container {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 700px;
  max-height: 90%; /* Limit height for smaller screens */
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  overflow-y: auto; /* Add vertical scrolling if content overflows */
}

/* Modal header styling */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
  word-break: break-word; /* Prevent text overflow */
}

/* Close button styling */
.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: #ff5a5a;
}

/* Modal body styling */
.modal-body {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  word-break: break-word; /* Prevent text overflow */
}

/* Responsive design */
@media (max-width: 768px) {
  .modal-container {
      padding: 15px;
      max-height: 80%; /* Reduce height further for smaller screens */
  }

  .modal-header h2 {
      font-size: 1.2rem;
  }

  .modal-body {
      font-size: 0.9rem;
  }
}

/* Modal footer styling */
.modal-footer {
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  background: #f9f9f9;
  text-align: right;
  position: sticky; /* Keep footer at the bottom */
  bottom: 0;
  z-index: 1;
}

/* Footer close button styling */
.modal-footer-close {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-footer-close:hover {
  background: #0056b3;
}

/* Responsive design */
@media (max-width: 768px) {
  .modal-footer-close {
      font-size: 0.9rem;
      padding: 8px 16px;
  }
}


/* Animation */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: scale(0.9);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}