.order-list input {
    height: 40px;
    width: 50px;
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 4px;
}

.order-list img{
    height: 35px;
    width: 35px;
}

.cancel-child {
    margin-left: 10px;
    cursor: pointer;
    color: #0044cc;
}
.cancel-child:hover{
    color: #0075ff;
}

/* Selected calander style */
.react-calendar__tile.selected {
    background: #1087ff;
    color: white;
    border-radius: 5px;
    margin-top: 2px;
}
.react-calendar__tile.selected:hover{
    background: #1087ff;
    color: white;
}

@media only screen and (max-width:767px){
    ul.p-2.list-group-item-dark.d-flex.justify-content-between.order-list {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width:1400px){
    ul.p-2.list-group-item-dark.d-flex.justify-content-between.order-list {
        flex-wrap: wrap;
    }
}

.no-class-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}


.menu-desc.row .select-child {
    width: 100%;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
}

.menu-desc.row .justify-content-between {
    display: none !important;
}

.top-bottom-shaps~.container .container.border.border-2.customClass .menu-desc.row .justify-content-between {
    display: flex !important;
}


.top-bottom-shaps~.container .container.border.border-2.customClass  .menu-desc.row .select-child {
    flex-wrap: nowrap;
    justify-content: initial;
    width: initial;
}
.top-bottom-shaps~.container .container.border.border-2 {
    max-width: 450px;
}
.top-bottom-shaps~.container .container.border.border-2.customClass {
    max-width: 100%;
}

.menu-desc.row .select-child select {
    margin: 0 !important;
}

@media (max-width: 991px) {
    .menu-desc .btn-1 {
        width: 100% !important;
        padding: 10px
    }
    
    .menu-desc span ~ .btn-1 {
        width: calc(100% - 50px) !important;
    }
    
    .menu-desc span {
        padding-left: 10px !important;
    }
}