.header .navbar.navbar-expand-xl .navbar-collapse ul.btn-header {
  display: flex;
  align-items: center;
  padding: 0 0 0 9px;
  margin: auto;
  padding-top: 6px;
}

.header .navbar .nav-item:not(:last-child) {
  margin: 0;
}

.header .navbar .nav-item:not(:first-child) {
  margin: 0;
}

.header .top-logo-nav {
  right: 0 !important;
}


@media only screen and (max-width: 1720px) {


  .header .navbar .nav-item:not(:first-child) {
    margin: 0;
  }

  .header .top-logo-nav {
    right: 0 !important;
    margin: 0 auto !important;
    left: 0 !important;
  }

  .header .navbar-brand img {
    width: auto !important;
    max-width: 240px;
    height: auto !important;
    margin-top: 15px !important;
  }


}

/**********************/

@media only screen and (max-width: 1440px) {

  .navbar-expand-xl .navbar-collapse {
    flex-wrap: wrap !important;
  }

  .header .top-logo-nav .tln-right {
    width: calc(100% - 240px);
  }

  .header .navbar {
    justify-content: flex-end !important;
  }

  .navbar-expand-xl .navbar-collapse {
    justify-content: end;
  }
}


@media only screen and (max-width: 1200px) {

  .header .navbar .nav-item:not(:last-child) {
    margin-bottom: 12px;
  }

  .header .navbar.navbar-expand-xl .navbar-collapse ul.btn-header {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 19px;
  }
}

@media only screen and (max-width:767px) {
  .header .top-logo-nav {
    display: flex;
    justify-content: space-between !important;
  }

  .header .navbar-brand img {
    max-width: 180px;
  }

  .header .top-logo-nav .tln-right {
    width: calc(100% - 150px);
  }

  .header .top-logo-nav .tln-right span {
    right: 0 !important;
  }

  .header .top-logo-nav .tln-right span span.m-3 {
    margin: 0 !important;
  }

}