@media only screen and (max-width:767px) {
    table.table.table-striped.text-center {
        table-layout: fixed !important;
        width: 500px !important;
    }

    table.table.table-striped.text-center.myOrder{
        table-layout: auto !important;
    }
    
    /* @media (max-width: 767px) { */
    h3,
    .h3 {
        font-size: 20px;
        text-align: center;
    }

    .my_transactions {
        display: inline-grid !important;
        text-align: center;
    }

    .transaction_titles {
        padding: 6px 40px 4px;
    }

    .table_fields {
        font-size: 13px !important;

    }

    /* } */

    .tab_tableItem {
        overflow: scroll !important;
    }

    .transactions_heading {
        margin-left: .5rem !important;

    }

    .transaction_btn {
        width: 116% !important;
    }

    .my_transactions {
        display: inline-grid !important;
    }

    .my_transactions h4 {
        margin-bottom: 18px !important;
    }

    .transactions_Details {
        display: inline-grid !important;

    }

    .transactions_Details h4 {
        margin: 0px 0px 5px -8px !important;
    }

}

.my-transaction {
    background-color: rgb(30, 30, 242);
}

.my-transaction span {
    color: white !important;
}

.bestil {
    background: #0ABB27 !important;
    color: white !important;
}

.transactions_head {
    font-size: 23px !important;
}