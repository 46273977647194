/* verifyOtp.css */

.verify-otp-sec {
    padding: 80px 0;
    max-width: 1060px;
    margin: 0 auto;
  }
  
  .verify-otp-inner {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    background: none;
    width: 100%;
  }
  
  #verifyform {
    padding: 70px 310px;
    min-height: 600px;
    text-align: center; 
  }
  
  .verify-otp-sec .label_wrap abbr {
    color: #ff0000;
  }
  
  #verifyotpformgroup input.form-control {
    height: 45px;
  }
  
  #verifyotpwrap {
    margin-bottom: 5px;
  }
  
  #verifyotptitle {
    padding-bottom: 40px;
  }
  
  #verifyotptitle h2 {
    font-weight: 600;
  }

  @media only screen and (max-width:767px){
    #verifyform {
      padding: 70px 20px;
    }
  }